/*
 * App Global CSS
 * ----------------------------------------------------------------------------
 * Put style rules here that you want to apply globally. These styles are for
 * the entire app and not just one component. Additionally, this file can be
 * used as an entry point to import other CSS/Sass files to be included in the
 * output CSS.
 * For more information on global stylesheets, visit the documentation:
 * https://ionicframework.com/docs/layout/global-stylesheets
 */

/* Core CSS required for Ionic components to work properly */
@import "~@ionic/angular/css/core.css";

/* Basic CSS for apps built with Ionic */
@import "~@ionic/angular/css/normalize.css";
@import "~@ionic/angular/css/structure.css";
@import "~@ionic/angular/css/typography.css";
@import '~@ionic/angular/css/display.css';

/* Optional CSS utils that can be commented out */
@import "~@ionic/angular/css/padding.css";
@import "~@ionic/angular/css/float-elements.css";
@import "~@ionic/angular/css/text-alignment.css";
@import "~@ionic/angular/css/text-transformation.css";
@import "~@ionic/angular/css/flex-utils.css";


.panel-white {
    border: 1px solid #888;
    background: #fff !important;
    background-color: #fff !important;
    padding: 8px;
    margin-bottom: 10px;
    color: #000;
}


.p-top {
    top: 0;
}
.p-bottom {
    bottom: 0;
}


.bg-gradient1 {
    background-image: linear-gradient(#88c6a4, #73c3dc);
    --background-image: linear-gradient(#88c6a4, #73c3dc);
}

.bg-gradient2 {
    background-image: linear-gradient(#8e7fc1, #e68a83);
}

.bg-wallpaper1 {
    background-image: url("./assets/img/wallpaper1.jpg");
    --background-image: url("./assets/img/wallpaper1.jpg");
    background-size: cover;
}

.bg-danger {
    background-color: #eb445a;
    --background-color: #eb445a;
}


.transparent {
background: transparent !important;
--background: transparent !important;
background-color: transparent !important;
--background-color: transparent !important;
--ion-color-base: transparent !important;
}

.txt-white-hover {
    color: #fff !important;
}
.txt-white-hover:hover {
    color:#fff !important;
}

.txt-accent2-hover {
    color: #88c7a3 !important;
}
.txt-accent2-hover:hover {
    color:#88c7a3 !important;
}
.txt-accent4-hover {
    color: #e2b587 !important;
}
.txt-accent4-hover:hover {
    color:#e2b587 !important;
}


.txt-accent1 {
    color: #252843 !important;
}
.txt-accent2 {
    color: #88c7a3 !important;
}
.txt-accent3 {
    color: #e9f8f0 !important;
}

.bg-accent1 {
    background-color: #252843 !important;
    --background-color: #252843 !important;
    background: #252843 !important;
    --background: #252843 !important;
}
.bg-accent2 {
    background-color: #88c7a3 !important;
    --background-color: #88c7a3 !important;
    background: #88c7a3 !important;
    --background: #88c7a3 !important;
}
.bg-accent3 {
    background-color: #e9f8f0 !important;
    --background-color: #e9f8f0 !important;
    background: #e9f8f0 !important;
    --background: #e9f8f0 !important;
}

.bdr-accent1 {
    border-color: #252843 !important;
}
.bdr-accent2 {
    border-color: #88c7a3 !important;
}
.bdr-accent3 {
    border-color: #e9f8f0 !important;
}

.bdr-5px {
    border-width: 5px;
}



.nav-selected {
    background: rgba(133, 194, 160, 0.2) !important;
    --background: rgba(133, 194, 160, 0.2) !important;
    border-right: 3px solid #85c2a0;
}
.nav-selected:hover {
    background: rgba(133, 194, 160, 0.3) !important;
    --background: rgba(133, 194, 160, 0.3) !important;
    color: #fff !important;
}


/*START ANIMATIONS*/
.animated {
    -webkit-animation-duration: 1s;
    animation-duration: 1s;
    -webkit-animation-fill-mode: both;
    animation-fill-mode: both;
}

.animate__animated__fast {
    -webkit-animation-duration: 0.4s;
    animation-duration: 0.4s;
    -webkit-animation-fill-mode: both;
    animation-fill-mode: both;
}
/*END ANIMATIONS*/


.nav-item {
    //font-weight: bold;
    text-transform: uppercase;

}


//STYLE WEBSITE PAGE SIZE
ion-header {
    background: #fff;
}
.page-container {
    max-width: 1400px !important;
    margin: 0 auto !important;
    font-weight: 400 !important;
}


.logo-small {
    width: 70px;
}


.hidden {
    visibility: hidden;
}
//END STYLE WEBSITE PAGE SIZE



.template-grid {
    display: inline-block;
    vertical-align: middle;
    margin: 0px 0px 20px;
    width: 31%;
    overflow: hidden;
    border: 1px solid #dcdcdc;
    position: relative;
    border-radius: 10px;
    text-align: left;
    margin: 1%;
}

.template-grid-single {
    vertical-align: middle;
    margin: 0px 0px 20px;
    overflow: hidden;
    border: 1px solid #dcdcdc;
    position: relative;
    border-radius: 10px;
    text-align: left;
}

.template-grid .bidButton {
    margin-top: -20px;
    width: 80%;
    margin-left: 10%;
}

.template-grid .title {
    //font-weight: bold;
    height: 40px;
    color: #333;
    width: 100%;
    bottom: 0;
    overflow: hidden;
}

.template-grid .details {
    color: #555;
}

.template-grid-image-container {
    background-position: center !important;
    background-size: cover !important;
    background-repeat: no-repeat !important;
    border-radius: 5px 5px 0px 0px;
    height: 200px;
    overflow: hidden;
    width: 120%;
    margin-left: -10%;
    background-color: #efefef;
}


.thumbnail-oversized {
    height: 120%; 
    width: 120%;
    margin-left: -10%;
    margin-top: -10%;
}





/* GLOBAL OVERRIDES */
.web-header {
    padding: 10px;
    overflow: visible;
}

.web-header:hover {
    --background: transparent;
    background: transparent;
}

.nav-filter {
    background: transparent; 
    --background: transparent;
    max-width: 150px;
}

.nav-filter-selected {
    border-radius: 20px;
    background: #007cdc; 
    color: #fff;
}

.nav-filter :hover {
    background: #074e75; 
    --background: #074e75;
    border-radius: 20px;
}

ion-item .nav-filter :hover {
    background: #074e75 !important; 
    --background: #074e75 !important;
}
    

.header-logo {
    width: 200px;
    height: auto;
    padding-left: 20px;
    padding-top: 10px;
}

/* TIMELINE */
/*Timeline element*/
.timeline-item {
    border-left: 5px solid #fff;
}

.timeline-item-selected {
    border-left: 5px solid #007cdc;
    --background: #dcdcdc;
    background: #dcdcdc;
    //background-color: #007cdc;
    //color: #fff;
}
/*End timeline element*/



.patientSearch {
    position: absolute;
    left: 100%;
    width: 100%;
    top: 0;
    z-index: 5;
}


//PRINT VARIABLES

.printable-content {
    background-color: #dcdcdc;
    overflow: auto;
}

.printable-wrapper {
    transform: scale(0.7);
    width: 10in;
    transform-origin: 0 50px;
}

.printable-area {
    background-color: #fff;
    margin: 0 auto;
}

.page-break {
    page-break-after: always;
    page-break-inside: avoid;
    clear: both;
}

.page-break-before {
    page-break-before: always;
    page-break-inside: avoid;
    clear: both;
}

#html-2-pdfwrapper2 {
    position: absolute;
    left: 20px;
    top: 350px;
    bottom: 0;
    overflow: auto;
    width: 600px;
}
//END PRINT VARIABLES



/* GLOBAL OVERRIDES */
.txt-head {
    font-family: Lato, "Open Sans", "Helvetica Neue", Helvetica, Arial, sans-serif !important;
    font-weight: 300;
}


.txt-avatar {
    background-color: #538fcd;
    display: table;
}

.txt-avatar-content {
    display: table-cell;
    text-transform: uppercase;
    text-align: center;
    height: 100%;
    width: 100%;
    vertical-align: middle;
}

// ion-item:hover {
//     --background: #dcdcdc;
//     background: #dcdcdc;
//     color: #000;
// }

.pad5 {
    padding: 5px;
}

.pad10 {
    padding: 10px;
}

.pad15 {
    padding: 15px;
}

.pad20 {
    padding: 20px;
}

.sm-show {
    display: none;
}


.hide {
    display: none;
}

/*.sc-ion-segment-md-h {
    --color-checked: #fff;
}*/

/*Override segment lower border*/
#receiptPreview {
    width: 100%;
    height: 100%;
    position: fixed;
    z-index: 100;
    left: 0;
    top: 0;
    background-color: rgba(0,0,0, 0.5);
    display: none;
}

#receiptPreview .receiptContent {
    top: 2.5%;
    left: 10%;
    right: 10%;
    bottom: 3%;
    position: absolute;
    z-index: 101;
    background-color: #fff;
    padding: 20px;
    padding-top: 50px;
    overflow: auto;
    border-radius: 20px;
}



hr {
    --ion-background-color: #dcdcdc;
    background-color: #dcdcdc;
}

#iconSidebar {
    max-width: 60px;
    line-height: 50px;
    min-width: 0px;
    background-color: #000;
    --ion-background-color: #0000;
    flex-shrink: unset;
    border-right: 1px solid #efefef;
}

#iconSidebar ion-button ion-icon {
    font-size: 30px;
}

#iconSidebar ion-button {
    min-height: 50px;
    margin-bottom: 10px;
}




.scroll-content {
    overflow: auto;
}

.selectable {
    -webkit-user-select: text;
    -moz-user-select: text;
    -ms-user-select: text;
    user-select: text;
}

.code {
    -webkit-user-select: text;
    -moz-user-select: text;
    -ms-user-select: text;
    user-select: text;
    background: #dcdcdc;
    --background: #dcdcdc;
    font-size: 10px;
    font-family: Courier New, Courier, monospace;
}

.resp {
    width: 100%;
    height: auto;
}

.vresp {
    height: 100%;
    width: auto;
}

.vs {
    height: 100%;
    overflow: auto;
    padding-right: 5px;
}

.vs-collpase {
    height: 100%;
    overflow: auto;
    padding-right: 5px;
}

.mw-30 {
    max-width: 30%;
}
.mw-33 {
    max-width: 33%;
}
.mw-40 {
    max-width: 40%;
}

.w100 {
    width: 100%;
}

.w80 {
    width: 80%;
}

.w75 {
    width: 75%;
}

.w66 {
    width: 66%;
}

.w50 {
    width: 50%;
}
.w40 {
    width: 40%;
}

.w33 {
    width: 33%;
}
.w30 {
    width: 30%;
}

.w25 {
    width: 25%;
}

.w20 {
    width: 20%;
}

.w10 {
    width: 10%;
}

.h100 {
    height: 100%;
}

.r10 {
    border-radius: 10px;
}

.r20 {
    border-radius: 20px;
}

.r30 {
    border-radius: 30px;
}

.p5 {
    padding: 5px;
}

.p10 {
    padding: 10px;
}

.p15 {
    padding: 15px;
}

.p20 {
    padding: 20px;
}
.p30 {
    padding: 30px;
}
.p40 {
    padding: 40px;
}
.p50 {
    padding: 50px;
}

.m5 {
    margin: 5px;
}
.m0 {
    margin: 0px;
}

.m10 {margin: 10px;}

.relative {
    position: relative;
}

.pointer {
    cursor: pointer;
}

.h100-segment {
    height: calc(100% - 55px);
}

.sm-h100-segment {
    height: 100%;
}

.section-fh-hidden {
    position: relative;
    height: 100%;
    overflow: hidden;
}

.section-fh {
    overflow: auto;
    position: relative;
    height: 100%;
}

.section-fh-border {
    overflow: auto;
    position: relative;
    border-right: 1px solid #efefef;
    height: 100%;
}

.section-fh-border-visible {
    position: relative;
    border-right: 1px solid #efefef;
    height: 100%;
    overflow: visible;
}

.collapse-companion {
    height: 100%;
    overflow: auto;
}

.lg-avatar {
    width: 125px;
    height: 125px;
    font-size: 45px;
    margin-bottom: 10px;
}
.el-center {
    margin: 0 auto;
}
.img-center {
    display: block;
    margin: 0 auto;
}

.txt-uppercase {
    text-transform: uppercase;
}

.txt-left {
    text-align: left;
}

.txt-center {
    text-align: center;
}

.txt-right {
    text-align: right;
}

.txt-primary {
    color: #007cdc;
}

.txt-danger {
    color: #eb445a;
}

.txt-warning {
    color: #e6b219;
}

.txt-white {
    color: #ffffff;
}

.txt-white-bg {
    color: #ffffff;
}

.txt-grey {
    color: #555555;
}

.txt-strike {
    text-decoration: line-through;
}

.txt-large {
    font-size: 25px;
}
.txt-medium {
    font-size: 18px;
}

.txt-small {
    font-size: 12px;
}

.txt-bold {
    font-weight: bold;
}

.txt-light {
    font-weight: 300;
}

.no-padding {
    padding: 0;
}

.no-margin {
    margin: 0;
}

.no-border {
    border: none;
}


.drop-cap-med {
    font-size: 30px;
    width: 60px;
    height: 60px;
    border-radius: 50%;
    line-height: 60px;
    background-color: #333;
    color: #fff;
    text-align: center;
}

.drop-cap-large {
    font-size: 30px;
    width: 80px;
    height: 80px;
    border-radius: 50%;
    line-height: 80px;
    background-color: #333;
    color: #fff;
    text-align: center;
}


.link-button-primary {
    color: #fff;
    background-color: #29c1f3;
    text-decoration: none;
    padding: 10px;
    border-radius: 20px;
    line-height: 40px;
}
.link-button-primary:hover {
    background-color: #29c1f3;
}

.link-button-success {
    color: #fff;
    background: #128f5b;
    --background: #128f5b;
    text-decoration: none;
    padding: 10px;
    border-radius: 20px;
    line-height: 40px;
}
.link-button-success:hover {
    background: #08ad68;
    --background: #08ad68;
}


.link-button-dark {
    color: #fff;
    background: #222;
    --background: #222;
    text-decoration: none;
    padding: 10px;
    border-radius: 20px;
    line-height: 40px;
}
.link-button-dark:hover {
    background: #444;
    --background: #444;
}


.nav-accent-bar {
    background: #01273a; 
    --background: #01273a;
    color: #fff;
    padding: 5px;
    z-index: 0;
}

.panel {
    border: 1px solid #d0d0d0;
    padding: 8px;
    margin-bottom: 10px;
    //background-color: #efefef
    border-radius: 20px;
}

.panel-spaced {
    border: 1px solid #d0d0d0;
    padding: 8px;
    margin-bottom: 40px;
}

.panel-dashed {
    border: 1px dashed #d0d0d0;
    padding: 8px;
    margin-bottom: 10px;
}

.panel-primary {
    background-color: #337ab7; 
    --background-color: #337ab7; 
    color: #fff;
    padding: 5px;
}

.panel-primary-lower {
    border: 5px solid #337ab7;
    background-color: #333; 
    --background-color: #333; 
    color: #fff;
    padding: 5px;
    border-radius: 0px 0px 10px 10px;
}

.panel-accent {
    background: #01273a; 
    --background: #01273a;
    color: #fff;
    padding: 5px;
}


.panel-light {
    background: #efefef;
    --background: #efefef;
    color: #222;
    padding: 5px;
}

.panel-dark {
    background: #222; 
    --background: #222;
    color: #fff;
    padding: 5px;
}

.panel-danger {
    background: #eb445a; 
    --background: #eb445a;
    color: #fff;
    padding: 5px;
}

.panel-success {
    background:#128f5b; 
    --background: #128f5b;
    color: #fff;
    padding: 5px;
}


.panel-float-bottom {
    position: absolute;
    z-index: 9;
    bottom: 0px;
    left: 0px;
    right: 0px;
    background-color: #333;
    color: #fff;
}

.panel-fh-overlay-hide {
    position: absolute;
    background-color: #fff;
    z-index: 15;
    display: none;
    height: 100%;
    border-right: 2px solid #333;
}

.panel-form-row-top {
    background-color: #666;
}

.panel-form-row {
    background-color: #efefef;
    padding: 20px;
    border: 1px dashed #666;
    border-top: none;
}

.panel-form-col-top {
    background-color: #dcdcdc;
    border: 1px solid #666;
    border-bottom: none;
}

.panel-form-col {
    background-color: #f9f9f9;
    padding: 20px;
    border: 1px solid #666;
    border-top: none;
}

.panel-form-obj-top {
    background-color: #fff;
    border: 1px solid #999;
    border-bottom: none;
}

.panel-form-obj {
    background-color: #fff;
    padding: 20px;
    border: 1px solid #999;
    border-top: none;
}

.screen-shade {
    width: 100%;
    height: 100%;
    position: fixed;
    z-index: 100;
    left: 0;
    top: 0;
    background-color: rgba(0,0,0, 0.5);
}

#lockScreen {
    width: 100%;
    height: 100%;
    position: fixed;
    z-index: 105;
    left: 0;
    top: 0;
    background-color: rgba(0,0,0, 0.5);
}

#lockScreen .lockContent {
    width: 500px;
    margin: 0 auto;
    margin-top: 50px;
    z-index: 110;
    background-color: #fff;
    padding: 20px;
    border-radius: 20px;
}



.closeModal {
    border-left: 1px solid #555;
    border-bottom: 1px solid #555;
    font-size: 18px;
    width: 60px;
    height: 45px;
    position: absolute;
    right: 0;
    top: 0;
    z-index: 120;
    border-radius: 0 0px 0 30px;
    text-align: center;
    line-height: 50px;
    background-color: #555;
    color: #fff;
    cursor: pointer;
}

.closeModal:hover {
    background-color: #333;
}

#modal {
    width: 100%;
    height: 100%;
    position: fixed;
    z-index: 100;
    left: 0;
    top: 0;
    background-color: rgba(0,0,0, 0.5);
}

#modal .modalContent {
    top: 2.5%;
    left: 10%;
    right: 10%;
    bottom: 3%;
    position: absolute;
    z-index: 101;
    padding: 20px;
    padding-top: 50px;
    overflow: auto;
    border-radius: 20px;
    border: 1px solid #555;
    color: #000;
}



#miniModal {
    width: 100%;
    height: 100%;
    position: fixed;
    z-index: 105;
    left: 0;
    top: 0;
    background-color: rgba(0,0,0, 0.5);
}

#miniModal .modalContent {
    top: 25%;
    left: 20%;
    right: 20%;
    bottom: 25%;
    position: absolute;
    z-index: 110;
    padding: 20px;
    overflow: auto;
    border-radius: 20px;
    border: 1px solid #555;
}

#microModal {
    width: 100%;
    height: 100%;
    position: fixed;
    z-index: 125;
    left: 0;
    top: 0;
    background-color: rgba(0,0,0, 0.5);
}

#microModal .modalContent {
    top: 30%;
    left: 30%;
    right: 30%;
    bottom: 30%;
    position: absolute;
    z-index: 130;
    padding: 20px;
    overflow: auto;
    border-radius: 20px;
    border: 1px solid #555;
}

/*pin pad*/
.pinPad {
    width: 100%;
}

.pinPad td {
    width: 33%;
    border: 1px solid #b8b8b8;
    background-color: #fdfdfd;
}

.pinPad button {
    width: 100%;
    height: 80px;
    text-align: center;
    vertical-align: middle;
    color: #000;
    font-size: 30px;
}

.pinPad button:active {
    background-color: #c0c3c7;
}

.pinInput {
    width: 100%;
    border: none;
    height: 50px;
    font-size: 30px;
    text-align: center;
    background-color: #efefef;
}


.bubble {
    background-color: #F2F2F2;
    border-radius: 5px;
    box-shadow: 0 0 6px #B2B2B2;
    display: inline-block;
    padding: 10px 18px;
    position: relative;
    vertical-align: top;
}

.bubble::before {
    background-color: #F2F2F2;
    content: "\00a0";
    display: block;
    height: 16px;
    position: absolute;
    top: 11px;
    transform: rotate( 29deg ) skew( -35deg );
    -moz-transform: rotate( 29deg ) skew( -35deg );
    -ms-transform: rotate( 29deg ) skew( -35deg );
    -o-transform: rotate( 29deg ) skew( -35deg );
    -webkit-transform: rotate( 29deg ) skew( -35deg );
    width: 20px;
}


.b-right {
    float: right;
    margin: 5px 20px 5px 45px;
    background-color: #DCF8C6;
}

.b-right::before {
    box-shadow: 2px -2px 2px 0 rgba( 178, 178, 178, .4 );
    right: -9px;
    background-color: #DCF8C6;
}

.b-left {
    float: left;
    margin: 5px 45px 5px 20px;
}

.b-left::before {
    box-shadow: -2px 2px 2px 0 rgba( 178, 178, 178, .4 );
    left: -9px;
}

.b-bottom {
    float: left;
    margin: 0px 45px 5px 0px;
}

.b-bottom::before {
    box-shadow: 2px 2px 2px 0 rgba( 178, 178, 178, .4 );
    top: calc(100% - 9px);
}

.b-top {
    float: left;
    margin: 0px 45px 5px 0px;
}

.b-top::before {
    box-shadow: -2px -2px 2px 0 rgba( 178, 178, 178, .4 );
    top: -9px;
}

.b-top-right {
    float: right;
    margin: 0px 15px 5px 0px;
}

.b-top-right::before {
    box-shadow: -2px -2px 2px 0 rgba( 178, 178, 178, .4 );
    top: -7px;
    right: 20px;
}


.tbl th {
    padding: 8px;
    font-size: 16px;
}

.tbl td {
    padding: 8px 5px 8px 5px;
    font-size: 16px;
}

.tbl tbody tr {
    border-top: 1px solid #dcdcdc;
}

.tbl-striped tr:nth-child(even) {
    background-color: #efefef;
}

.tbl-striped tr:hover {
    background-color: #dcdcdc !important;
}


.searchbar-md .searchbar-input {
    background-color: #f4f4f4;
    border: 1px solid #f0f0f0;
    padding: 5px 10px 5px 10px;
    -webkit-box-shadow: none;
    box-shadow: none;
    font-size: inherit;
    line-height: inherit;
}

.searchbar-md .searchbar-search-icon {
    display: none;
}

// ion-item:hover {
//     background-color: #dcdcdc;
//     color: #000;
// }

.td-input {
    border: 1px solid #dcdcdc;
}

.ion-input ion-label {
    border-right: 3px double #88c7a3;
    margin-right: 5px;
}

.ion-input .label-md {
    color: #555;
    font-size: 14px;
    //font-weight: 600;
}

.ion-input .native-input {
    background-color: #f4f4f4;
    --background: #f4f4f4;
    border: 1px solid #f0f0f0;
    padding: 5px 10px 5px 10px;
    --placeholder-font-weight: 100;
    //font-weight: 100;
}

.ion-input ion-select {
    background-color: #f4f4f4;
    background: #f4f4f4;
    border: 1px solid #f0f0f0;
    padding: 5px 10px 5px 10px;
    width: 100%;
    margin: 0px;
}

.item-md.item-input.ng-valid.item-input-has-value:not(.input-has-focus):not(.item-input-has-focus) .item-inner,
.item-md.item-input.ng-valid.input-has-value:not(.input-has-focus):not(.item-input-has-focus) .item-inner,
.list-md .item-input.ng-valid.item-input-has-value:not(.input-has-focus):not(.item-input-has-focus):last-child,
.list-md .item-input.ng-valid.input-has-value:not(.input-has-focus):not(.item-input-has-focus):last-child {
    border-bottom-color: rgb(222, 222, 222);
    /*border-bottom-color: transparent;*/
    /*border-bottom: none;*/
    box-shadow: none;
}




.ion-input-float ion-label {
    padding-bottom: 10px;
}

.ion-input-float .label-md {
    color: #555;
    font-size: 14px;
    font-weight: 600;
}

.ion-input-float .native-input {
    background-color: #f4f4f4;
    --background: #f4f4f4;
    border: 1px solid #f0f0f0;
    padding: 5px 10px 5px 10px;
    --placeholder-font-weight: 100;
    font-weight: 100;
}

.ion-input-float ion-select {
    background-color: #f4f4f4;
    background: #f4f4f4;
    border: 1px solid #f0f0f0;
    padding: 5px 10px 5px 10px;
    width: 100%;
    margin: 0px;
}





/*.ion-input {
  border: 1px solid #dcdcdc;
  border-radius: 5px;
  margin-bottom: 3px;
}*/


.lg-collapse-companion {
    width: 100%;
    max-width: 100%;
    min-width: 100%;
    position: absolute;
    z-index: 3;
    background-color: #fff;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    padding: 5px;
}


.dark-hide { display: block; }
.dark-show { display: none; }
.light-hide { display: none; }
.light-show { display: block; }

//START DARK THEME DARK
//@media (prefers-color-scheme: dark) {
body.dark {
    .dark-hide {
        display: none;
    }

    .dark-show {
        display: block;
    }

    .light-hide {
        display: block;
    }

    .light-show {
        display: none;
    }

    .txt-white-bg {
        color: #000;
    }

    .bubble {
        background-color: #222;
        box-shadow: 0 0 6px #B2B2B2;
    }

    .bubble::before {
        background-color: #222;
    }




    .panel {
        border: 1px solid #888;
        padding: 8px;
        margin-bottom: 10px;
    }

    


    .panel-spaced {
        border: 1px solid #444;
    }

    .section-fh-border {
        border-right: 1px solid #333;
    }


    .logo {
        -webkit-filter: invert(1) hue-rotate(180deg);
        filter: invert(1) hue-rotate(180deg);
    }

    .img-ui {
        -webkit-filter: invert(1) hue-rotate(180deg);
        filter: invert(1) hue-rotate(180deg);
    }

    // ion-item:hover {
    //     --background: #333;
    //     background: #333;
    //     color: #fff;
    // }



    

    .profile-members-container {
        background-color: #444444;
    }


    .profile-members:hover {
        background-color: #666666;
    }
}
//}
//END DARK THEME DARK 


.btn-selected-white {
    background-color: #007cdc;
    color: #fff;
    --ion-color-base: #fff;
}


/*start dark segment*/
.segment-container {
    background-color: #222;
    margin-top: 10px;
    margin-bottom: 10px;
}

.segment-container-button {
    width: 50%;
    margin: 0px;
}

.selected {
    --background: #007cdc !important;
    background: #007cdc !important;
    color: #fff !important;
    --ion-color-base: #fff !important;
}

.selected:hover {
    --background: #0061af;
    background: #0061af;
    color: #fff;
}

.selected-white {
    background-color: #007cdc;
    --background-color: #007cdc;
    color: #fff;
    --ion-color-base: #fff;
}

.selected-white:hover {
    background-color: #0061af;
    --background-color: #0061af;
    color: #fff;
    --ion-color-base: #fff;
}

.selected-border {
    border-bottom: 3px solid #007cdc;
}

.selected-border-light {
    border-bottom: 3px solid #dcdcdc;
}

.disabled {
    opacity: 0.4;
}

.disabled:hover {
    opacity: 0.5;
}

.segment-button:hover {
    background-color: #dcdcdc;
}


.es-segment {
    background-color: #000;
    color: #fff;
    overflow-x: auto;
    border-radius: 5px 5px 0px 0px;
    padding: 0 10px 0 10px;
}


.dark-pill {
    background-color: #000;
    color: #fff;
    border-radius: 30px;
    min-width: 50px;
    max-width: 150px;
}

/*.dark-pill:hover {
    background-color: #444;
}

.dark-segment {
    background-color: #000;
    color: #fff;
    overflow-x: auto;
    border-radius: 5px 5px 0px 0px;
    padding: 0 10px 0 10px;
}

.dark-segment ion-segment-button:hover {
    background-color: #444;
}

.dark-segment ion-segment-button.segment-button-checked:hover {
    background-color: #fff;
}*/

.segment-fix {
    justify-content: flex-start;
    width: 100%;
    text-align: center;
}

/*.dark-segment .segment-button-layout-icon-top {
    color: #fff;
}

.dark-segment .segment-button-checked {
    color: #000;
    background-color: #fff;
    --background-color: #fff;
    border: 2px solid #000;
    border-bottom: 2px solid #fff;
    border-radius: 5px 5px 0 0;
}*/


.light-segment {
    background-color: #888;
    color: #000;
    border-bottom: 2px solid #fff;
    overflow-x: auto;
}

.light-segment .segment-button-layout-icon-top {
    color: #fff;
}

.light-segment .segment-button-checked {
    color: #000;
    background-color: #fff;
    --background-color: #fff;
    border: 2px solid #dcdcdc;
    border-bottom: 2px solid #fff;
    border-radius: 5px 5px 0 0;
}

/*end dark segment*/


/*Profile elements*/
.profile {
    position: relative;
    background-color: #333;
    min-height: 150px;
}

.profile .edit {
    position: absolute;
    top: 0;
    right: 0;
    color: #fff;
    background-color: rgba(0,0,0,0.3);
}

.profile .sysinfo {
    background-color: rgba(0,0,0,0.7);
    padding: 5px;
    position: absolute;
    bottom: 0px;
    font-size: 12px;
    color: #fff;
    width: 100%;
    //font-weight: bold;
}


.profile-members {
    width: 50%;
    display: inline-block;
    vertical-align: top;
    cursor: pointer;
    padding: 2px;
}


.profile-members:hover {
    background-color: #dcdcdc;
    color: #fff;
    cursor: pointer;
}

.profile-members-container {
    padding-top: 5px;
    padding-bottom: 5px;
    background-color: #efefef;
}


.profile-members-selected {
    background-color: #fff;
    color: #000;
}

.profile-members-hide {
    display: none;
}

/*table Element*/

.tbl-striped {
    color: #333;
}
.tbl-striped td {
    padding: 5px;
}

.tbl-striped tr:nth-child(even) {
    background: #f7fcf9;
}

.tbl-striped th {
    padding: 5px;
}
/* End table element */



/* Overrides */
ion-select {
    max-width: 100%;
    margin: 13px 8px;
}
/* End Overrides */


/*
xs 0 size-
Set columns when (min-width: 0)

sm 576px size-sm-
Set columns when (min-width: 576px)


md 768px size-md-
Set columns when (min-width: 768px)

lg 992px size-lg-
Set columns when (min-width: 992px)

xl 1200px size-xl-
Set columns when (min-width: 1200px)
*/


/* LG SCREEN OVERRIDES */
@media (min-width: 992px) {
    /*make ion-input labels wider*/
    .label-fixed.sc-ion-label-md-h {
        min-width: 140px;
        max-width: 300px;
    }

    /*make confirmation and alert boxes wider*/
    .alert-wrapper.sc-ion-alert-md {
        min-width: 400px;
        max-width: 600px;
    }

    .lg-hide {
        display: none;
    }

    .lg-show {
        display: block;
    }

    .header {
        display: none;
    }

    .scroll-content {
        margin-top: 0px;
    }

    .template-grid {
        width: 23%;
    }
}

/* MID & SMALL SCREEN OVERRIDES */
@media (max-width: 992px) {
    .lg-show {
        display: none;
    }

    .lg-hide {
        display: block;
    }
}


@media screen and (max-width: 576px) {
    /*if mobile screen*/
    .template-grid {
        width: 48%;
    }

    .sm-show {
        display: block;
    }

    .sm-hide {
        display: none;
    }

    .sm-h100-segment {
        height: calc(100% - 55px);
    }


    .vs-collpase {
        height: auto;
    }

    .section-fh-border {
        border-right: 0px;
    }

    body.dark {
        .section-fh-border {
            border-right: 0px;
        }
    }

    .patientSearch {
        position: absolute;
        left: 0;
        width: 100%;
        top: 10px;
        z-index: 5;
    }

    .col-sm-hide {
        height: 100%;
        width: 100%;
        max-width: 100%;
        position: absolute;
        z-index: 10;
        background: #fff;
        display: none;
    }

    table .collapse > td {
        width: 100%;
        display: block;
    }

    .printable-wrapper {
        transform: scale(0.5);
    }

    .collapse {
        width: 100%;
        max-width: 100%;
        min-width: 100%;
        overflow: auto;
        height: auto;
    }

    .collapse-stack {
        height: auto;
        overflow: unset;
        width: 100%;
        max-width: 100%;
        min-width: 100%;
    }

    .collapse-companion {
        width: 100%;
        max-width: 100%;
        min-width: 100%;
        position: absolute;
        z-index: 10;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        padding: 5px;
    }

    .mobile-header {
        margin-bottom: 10px;
    }

    #modal .modalContent {
        top: 1%;
        left: 1%;
        right: 1%;
        bottom: 1%;
        position: absolute;
        z-index: 101;
        padding: 20px;
        padding-top: 50px;
        overflow: auto;
        border-radius: 20px;
    }


    #lockScreen {
        width: 100%;
        height: 100%;
        position: fixed;
        z-index: 105;
        left: 0;
        top: 0;
        background-color: rgba(0,0,0, 0.5);
    }

    #lockScreen .lockContent {
        width: 100%;
        margin: 0 auto;
        margin-top: 50px;
        z-index: 110;
        background-color: #fff;
        padding: 20px;
        border-radius: 20px;
    }
}



/* ANIMATIONS */

.animated {
    -webkit-animation-duration: 1s;
    animation-duration: 1s;
    -webkit-animation-fill-mode: both;
    animation-fill-mode: both;
}

.animated-fast {
    -webkit-animation-duration: 0.4s;
    animation-duration: 0.4s;
    -webkit-animation-fill-mode: both;
    animation-fill-mode: both;
}

.animated-show {
    display: block;
    -webkit-animation-duration: 0.3s;
    animation-duration: 0.3s;
    -webkit-animation-fill-mode: both;
    animation-fill-mode: both;
    z-index: 10;
}



@-webkit-keyframes fadeIn {
    from {
        opacity: 0;
    }

    to {
        opacity: 1;
    }
}

@keyframes fadeIn {
    from {
        opacity: 0;
    }

    to {
        opacity: 1;
    }
}

.fadeIn {
    -webkit-animation-name: fadeIn;
    animation-name: fadeIn;
}


@-webkit-keyframes fadeInDown {
    from {
      opacity: 0;
      -webkit-transform: translate3d(0, -100%, 0);
      transform: translate3d(0, -100%, 0);
    }
  
    to {
      opacity: 1;
      -webkit-transform: none;
      transform: none;
    }
  }
  
  @keyframes fadeInDown {
    from {
      opacity: 0;
      -webkit-transform: translate3d(0, -100%, 0);
      transform: translate3d(0, -100%, 0);
    }
  
    to {
      opacity: 1;
      -webkit-transform: none;
      transform: none;
    }
  }
  
  .fadeInDown {
    -webkit-animation-name: fadeInDown;
    animation-name: fadeInDown;
  }

@-webkit-keyframes fadeInLeft {
    from {
      opacity: 0;
      -webkit-transform: translate3d(-100%, 0, 0);
      transform: translate3d(-100%, 0, 0);
    }
  
    to {
      opacity: 1;
      -webkit-transform: none;
      transform: none;
    }
  }
  
  @keyframes fadeInLeft {
    from {
      opacity: 0;
      -webkit-transform: translate3d(-100%, 0, 0);
      transform: translate3d(-100%, 0, 0);
    }
  
    to {
      opacity: 1;
      -webkit-transform: none;
      transform: none;
    }
  }
  
  .fadeInLeft {
    -webkit-animation-name: fadeInLeft;
    animation-name: fadeInLeft;
  }


@-webkit-keyframes fadeInRight {
    from {
      opacity: 0;
      -webkit-transform: translate3d(100%, 0, 0);
      transform: translate3d(100%, 0, 0);
    }
  
    to {
      opacity: 1;
      -webkit-transform: none;
      transform: none;
    }
  }
  
  @keyframes fadeInRight {
    from {
      opacity: 0;
      -webkit-transform: translate3d(100%, 0, 0);
      transform: translate3d(100%, 0, 0);
    }
  
    to {
      opacity: 1;
      -webkit-transform: none;
      transform: none;
    }
  }
  
  .fadeInRight {
    -webkit-animation-name: fadeInRight;
    animation-name: fadeInRight;
  }


